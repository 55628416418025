import GlobalHoverService from 'o365.controls.GlobalHoverService.ts';
import ActivityHover from 'complete.vue.components.ActivityHover.vue';

export default {
    mounted(el: HTMLElement, binding: any) {
        const service = GlobalHoverService.getService();
        service.bindElement(el, {
            component: ActivityHover,
            props: {
                activityId: binding.value,
            },
        })
    },
    updated(el: HTMLElement, binding: any) {
        const service = GlobalHoverService.getService();
        const props = service.getPropsFromEl(el)
        if (props && props.activityId !== binding.value) {
            props.activityId = binding.value;
        }
    },
    unmounted(el: HTMLElement, _binding: any) {
        const service = GlobalHoverService.getService();
        service.unbindElement(el);
    }
};